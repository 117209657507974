<template>
  <v-btn
    class="orange white--text"
    small
    @click="start"
  >
    {{'action.reset-password'| t}}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import listActionMixins from '@/components/list/listDataAction/listDataAction.js'
export default {
  mixins: [listActionMixins],
  methods: {
    start() {
      this.$apopup.base({
        title: this.$t('action.reset-password'),
        bodySlot: () => import('./forceResetMemberPasswordPopup.vue'),
        disabledApply: (formData) => {
          if(!formData) return true
          if(!window.helper.validator.minText(formData.password, 2)) return true
          if(!window.helper.validator.minText(formData.password_confirm, 2)) return true
          if(formData.password != formData.password_confirm) return true
          return false
        },
        applyCallback: (formData) => {
          setTimeout(() => {
            this.$helper.account.verify(() => this.resetPassword(formData))
          }, 200)
        },
      })
    },
    async resetPassword(formData) {
      try {
        await this.$api.collection.memberApi.resetPassword(this.row.id, formData)
        this.$snotify.success(
          null,
          this.$t('save.successfully')
        )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('save.failure')
        )
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>